import React from 'react'
import { Link } from 'gatsby'

import SEO from '../components/SEO'
import PageHeader from '../components/Shared/PageHeader'

const breadcrumb = [
  { title: 'Home', url: '/' },
  { title: 'Contact', url: '/contact' },
]

const ContactPage = () => (
  <>
    <SEO title="Contact Us" />
    <PageHeader header="Contact Us" breadcrumb={breadcrumb} />

    <div className="block">
      <div className="container">
        <div className="card mb-0">
          <div className="card-body contact-us">
            <div className="contact-us__container">
              <div className="row">
                <div className="col-12 col-lg-6 offset-lg-3">
                  <h4 className="contact-us__header card-title">
                    Leave us a Message
                  </h4>

                  <form
                    id="ajax-contact-form"
                    method="POST"
                    action="https://formspree.io/hello@rawdogfood.co.uk"
                  >
                    <input type="text" name="_gotcha" className="hidden" />
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="form-name">Name</label>
                        <input
                          type="text"
                          name="name"
                          id="form-name"
                          className="form-control"
                          placeholder="Your Name"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="form-email">Email</label>
                        <input
                          type="email"
                          id="form-email"
                          name="_replyto"
                          className="form-control"
                          placeholder="Email Address"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="form-subject">Subject</label>
                      <input
                        type="text"
                        name="subject"
                        id="form-subject"
                        className="form-control"
                        placeholder="Subject"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="form-message">Message</label>
                      <textarea
                        id="form-message"
                        name="message"
                        className="form-control"
                        rows="4"
                      />
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Send Message
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default ContactPage
